@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Faster+One&display=swap');

@font-face {
		font-family: 'Golf Club Homicide';
		src: url('fonts/GolfClubHomicide.woff2') format('woff2'),
				url('fonts/GolfClubHomicide.woff') format('woff');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
}

/* Always set the map height explicitly to define the size of the div
 * element that contains the map. */
#map {
	height: calc(100% - 100px);
}

#header {
	background-color: #f6f9fa;
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	width: 100%;
	height: 100px;
	margin: 0;
	padding: 0;
}

#header .logo {
	width: 143px;
	display: inline-block;
	text-align: left;
	margin: 17px 0 16px 1rem;
	padding: 0;
	font-weight: 700;
}

#header a {
	text-decoration: none;
	color: #000000;
}

#header #popsicle {
	font-size: 1.4em;
	transform: translateY(-1.1em);
	display: inline-block;
}

#header #hunt {
	color: red;
	font-weight: normal;
	display: inline-block;
	font-size: 1.4em;
	transform: translateY(-1.4em) translateX(46px);
}

#header .links {
	/* Why are there 4px missing !? */
	width: calc(100% - 147px - 1rem);
	text-align: right;
	display: inline-block;
	font-weight: bold;
	margin: 0;
	transform: translateY(-27px);
}

#header .links .link {
	margin-right: 1rem;
}

#header .links .link a {
	text-decoration: none;
	color: #000000;
}

#header .links .link a#home {
	color: #2bd2f7;
}

.map_infobox {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
}

/*
 * Things which serve as wrappers need to be given no additional space
 */
html, body, #root {
	height: 100%;
	margin: 0;
	padding: 0;
}
